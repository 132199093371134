import React, { Component } from "react";
import { withFormik } from "formik";
import { StaticQuery, graphql, navigate } from "gatsby";
import { Buffer } from "buffer";

import UpsellSelect from "../components/UpsellSelect/upsell-select";
import submitProduct from "../utils/submit-product";
import navigateToCheckout from "../utils/navigate-checkout";

// TODO: refactor this so everything isn't in render fucntion.
class Upsell extends Component {
	checkCart() {
		const { cart, queryString } = this.props;
		if (
			!cart ||
			!cart.lineItems ||
			!Array.isArray(cart.lineItems) ||
			cart.lineItems.length === 0
		) {
			navigate("/cart");
		}

		if (cart && cart.lineItems && Array.isArray(cart.lineItems)) {
			const hasUpsell = cart.lineItems.find((item) => {
				return item.title.includes("Special Offer");
			});

			if (hasUpsell) {
				navigateToCheckout(cart, queryString);
			}
		}
	}

	componentDidMount() {
		this.checkCart();
	}

	shouldComponentUpdate() {
		this.checkCart();
		return true;
	}

	render() {
		const { queryString } = this.props;

		const selectProduct = ({ shopifyId }) => {
			const id = Buffer.from(shopifyId, "utf-8").toString("base64");

			const values = {
				id,
				quantity: 1,
			};

			const bag = {
				props: this.props,
				setSubmitting: () => {
					const { cart } = this.props;
					navigateToCheckout(cart, queryString);
				},
				upsell: true,
			};

			return submitProduct(values, bag);
		};

		return (
			<>
				<UpsellSelect
					title="Additional Items"
					{...this.props}
					selectProduct={selectProduct}
				/>
			</>
		);
	}
}

const initalValues = {
	id: 0,
	quantity: 1,
	squeaker: "1",
};

const UpsellForm = withFormik({
	mapPropsToValues: () => initalValues,
	handleSubmit: submitProduct,
})(Upsell);

export default (props) => (
	<StaticQuery
		query={graphql`
			query upsellQuery {
				specialOffers: allShopifyCollection(
					filter: { title: { eq: "Special Offer" } }
				) {
					edges {
						node {
							products {
								id
								shopifyId
								title
								descriptionHtml
								productType
								handle
								tags
								# images {
								#     originalSrc
								#     localFile {
								#         childImageSharp {
								#             fluid {
								#                 ...GatsbyImageSharpFluid_withWebp_noBase64
								#             }
								#         }
								#     }
								# }
								featuredImage {
									originalSrc
									localFile {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp_noBase64
											}
										}
									}
								}
								variants {
									id
									shopifyId
									sku
									price
									presentmentPrices {
										price {
											amount
											currencyCode
										}
									}
									compareAtPrice
									availableForSale
									title
									weight
									weightUnit
									image {
										originalSrc
										localFile {
											childImageSharp {
												fluid {
													...GatsbyImageSharpFluid_withWebp_noBase64
												}
											}
										}
									}
									selectedOptions {
										name
										value
									}
								}
							}
						}
					}
				}

				combos: allShopifyCollection(
					filter: { title: { eq: "Combos" } }
				) {
					edges {
						node {
							products {
								id
								shopifyId
								title
								descriptionHtml
								productType
								handle
								tags
								# images {
								#     originalSrc
								#     localFile {
								#         childImageSharp {
								#             fluid {
								#                 ...GatsbyImageSharpFluid_withWebp_noBase64
								#             }
								#         }
								#     }
								# }
								featuredImage {
									originalSrc
									localFile {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp_noBase64
											}
										}
									}
								}
								variants {
									id
									shopifyId
									sku
									price
									compareAtPrice
									availableForSale
									title
									weight
									weightUnit
									image {
										originalSrc
										localFile {
											childImageSharp {
												fluid {
													...GatsbyImageSharpFluid_withWebp_noBase64
												}
											}
										}
									}
									selectedOptions {
										name
										value
									}
								}
							}
						}
					}
				}
			}
		`}
	>
		{(data) => <UpsellForm data={data} {...props} />}
	</StaticQuery>
);
